<template>
  <agile :dots="false" :autoplay="true" :autoplay-speed="5000">
    <div
      class="slide"
      v-for="n of slides"
      :key="n.id"
      :class="`slide--${n.id}`"
    >
      <div class="carousel__item">
        <img
          :src="n?._embedded?.['wp:featuredmedia']?.['0']?.source_url"
          alt="Example slide description"
          loading="lazy"
        />
        <div class="carousel__content">
          <span class="carousel__badge" v-html="n?.acf.top_tag"></span>
          <h3 class="carousel__title" v-html="n?.title.rendered"></h3>
          <span class="carousel__subtitle" v-html="n?.acf.bot_des"> </span>
          <a :href="n?.acf.link"
            ><button class="btn btn-primary">подробнее</button></a
          >
        </div>
      </div>
    </div>
    <template v-slot:prevButton><icon-arrow-left /></template
    ><template v-slot:nextButton><icon-arrow-right /></template>
  </agile>
</template>

<script>
import { VueAgile } from "vue-agile";
import IconArrowRight from "@/components/Icons/IconArrowRight.vue";
import IconArrowLeft from "@/components/Icons/IconArrowLeft.vue";
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    agile: VueAgile,
    IconArrowRight,
    IconArrowLeft,
  },
  setup() {
    const store = useStore();
    const slides = computed(() => store.getters.getSlides);

    return {
      slides,
    };
  },
};
</script>
